const routes = [
  {
    path: '/platform',
    component: () => import('@/views/request/platform/PlatformView.vue'),
  },
  {
    path: '/environment',
    component: () => import('@/views/request/environment/EnvironmentView.vue'),
  },
  {
    path: '/status',
    component: () => import('@/views/request/status/StatusView.vue'),
  },
];

export default routes;
