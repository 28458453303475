const routes = [
  {
    path: '/login',
    component: () => import('@/views/service/login/LoginView.vue'),
  },
  {
    path: '/manager',
    component: () => import('@/views/service/manager/ManagerView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/transaction',
    component: () => import('@/views/service/transaction/TransactionView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/user',
    component: () => import('@/views/service/user/UserView.vue'),
    meta: { requiresAuth: true },
  },
];

export default routes;
