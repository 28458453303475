const routes = [
  {
    path: '/guide',
    component: () => import('@/views/support/guide/GuideView.vue'),
  },
  {
    path: '/qna',
    component: () => import('@/views/support/qna/QnaView.vue'),
    name: 'qna',
    props: true,
  },
  {
    path: '/qna/:questNo',
    component: () => import('@/views/support/qna/QnaDetailView.vue'),
    name: 'qnaDetail',
    props: true,
    beforeEnter: (to, from, next) => {
      if (from.path !== '/qna') {
        next('/qna');
      } else {
        next();
      }
    },
  },
  {
    path: '/qnaInsert',
    component: () => import('@/views/support/qna/QnaInsertView.vue'),
  },
];

export default routes;
