const COMMON_GROUP_CODE = {
  CODE_RULE_HEALTH_CODE: '9', // 헬스코드(알람 조건 관리)
  CODE_SUBSCRIPTION_HEALTH_CODE: '10', // 헬스코드(구독 관리)
};

// 알람 범위 목록
const RULE_SCOPE_LIST = [
  {
    value: '==',
    name: '==',
  },
  {
    value: '>',
    name: '>',
  },
  {
    value: '>=',
    name: '>=',
  },
  {
    value: '<=',
    name: '<=',
  },
  {
    value: '<',
    name: '<',
  },
];

export { COMMON_GROUP_CODE, RULE_SCOPE_LIST };
