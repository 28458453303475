import bus from '@/utils/bus';
import router from '@/routes/index';
import store from '@/store/index';

/**
 * 오류 공통처리
 * @param {Error} error
 */
export function handleError(error) {
  console.log('handleError:::', error, error.response);
  // 로그인 페이지 오류
  if (!error.response) {
    bus.$emit('end:spinner');
    router.push('/error');
    //Vue.dialogs.alert("서버와의 통신에 문제가 있습니다.");
  }
  // 401
  else if (error.response.status == 401) {
    store.dispatch('auth/CLEAR_SESSION');
  } else {
    alert(`오류가 발생했습니다 :`, error.response.status);
    return;
  }
}
