<template>
  <footer class="footer">
    <div class="footer-inner">
      <figure class="footer-ci">
        <img src="@/assets/libs/images/logo_footer.png" alt="" />
      </figure>
      <div class="footer-info">
        <p>
          <span>Offices</span>5th fl. 127, Yangsan-ro, Yeongdeungpo-gu, Seoul,
          Republic of Korea
        </p>
        <p><span>Tel</span>02-780-1340</p>
        <p><span>E-mail</span>admin@dkitec.com</p>
        <div class="copyright">
          Copyri ght 2019 DKI Technology Co,.Ltd. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
