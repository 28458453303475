<template>
  <CommonPopup v-if="items.show" @close="onClose" :item="items" />
</template>

<script>
import CommonPopup from './CommonPopup.vue';

export default {
  components: { CommonPopup },
  data() {
    return {
      items: {},
    };
  },
  methods: {
    open(message, type) {
      if (!this.$parent) {
        this.$mount();
        document.body.appendChild(this.$el);
      }
      return new Promise(resolve => {
        const item = {
          show: true,
          message,
          type,
          resolve,
        };
        this.items = item;
      });
    },
    alert(message) {
      return this.open(message, 'alertPopup');
    },
    confirm(message) {
      return this.open(message, 'confirmPopup');
    },
    loading() {
      return this.open('', 'loading');
    },
    ready_service() {
      return this.open('', 'popup_ready_service');
    },
    onClose(item, ok) {
      const response = ok;
      item.resolve(response);
      item.show = false;
      this.close(item);
    },
    close() {
      this.items = Object.assign(this.$data, this.$options.data);
    },
  },
};
</script>

<style></style>
