/**
 * 다른 모듈의 state를 참조하기 위해서는
 * actions와 getters에서 rootState를 활용해준다.
 * mutations에서 활용해야 하는 경우, actions에서 내려줘야 함.
 * https://vuex.vuejs.org/kr/guide/modules.html
 */
import { processLogin, logout, sesstionCehck } from '@/api/authApi';

export default {
  namespaced: true,
  state: {
    isLogined: false,
    portalToken: sessionStorage.getItem('portalToken') || null,
    userName: sessionStorage.getItem('userName') || null,
  },
  actions: {
    // 로그인
    async LOGIN({ commit }, loginInfo) {
      const { data } = await processLogin(loginInfo);
      commit('SET_LOGIN', data);
      return data;
    },

    // 로그아웃
    async LOGOUT({ commit }) {
      const { data } = await logout();
      commit('SET_LOGOUT');
      return data;
    },

    // 서비스 관리 메뉴 로그인 체크
    async SESSION_CHECK({ commit }) {
      const data = await sesstionCehck();
      return commit('SESSION_CHECK', data);
    },

    // sessionStorage 초기화
    async CLEAR_SESSION({ commit }) {
      return commit('SET_LOGOUT');
    },
  },
  mutations: {
    // 로그인 mutation
    SET_LOGIN(state, tokenData) {
      if (tokenData.valid) {
        state.isLogined = true;
        state.portalToken = tokenData.token;
        state.userName = tokenData.userName;
        sessionStorage.setItem('portalToken', tokenData.token);
        sessionStorage.setItem('userId', tokenData.userId);
        sessionStorage.setItem('userName', tokenData.userName);
        sessionStorage.setItem('srvcId', tokenData.serviceId);
      }
    },

    SET_LOGOUT(state) {
      sessionStorage.removeItem('portalToken');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('srvcId');

      state.isLogined = false;
      state.portalToken = sessionStorage.getItem('portalToken') || null;
      state.userName = sessionStorage.getItem('userName') || null;
    },
    SESSION_CHECK(state, data) {
      if (data?.data?.nextToken) {
        sessionStorage.setItem('portalToken', data?.data?.nextToken);
        state.portalToken = data?.data?.nextToken;
      }
    },
  },
  getters: {
    isLogin(state) {
      return state.portalToken !== null;
    },
    portalToken(state) {
      return state.portalToken;
    },
    userName(state) {
      return state.userName;
    },
  },
};

/* function toISOString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
} */
