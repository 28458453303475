import { service, serviceInterceptor } from './index';

function sessionDuplication({ userId }) {
  return service.get(`/session/duplication/${userId}`);
}

function deleteLoginStatus({ userId, password }) {
  return service.post(`/session/user/${userId}`, { userId, password });
}

function processLogin({ userId, password }) {
  return service.post(`/session/login`, { userId, password });
}

function logout() {
  return serviceInterceptor.post(`/session/logout`);
}
function sesstionCehck() {
  return serviceInterceptor.get(`/session/token`);
}

export {
  sessionDuplication,
  deleteLoginStatus,
  processLogin,
  logout,
  sesstionCehck,
};
