<template lang="">
  <div>
    <div class="snb">
      <div class="snb-inner">
        <div class="loc-left">
          <ol>
            <li v-for="(data, index) in pathList" :key="index">
              <a href="#">{{ data }}</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="sub-top">
      <div class="sub-title">
        <h2>
          {{ title.mainTitle }}
          <small>{{ title.smallTitle }}</small>
        </h2>
        <i class="sub-top-fig"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pathList: {
      type: Array,
    },
    title: {
      type: Object,
    },
  },
};
</script>
<style lang=""></style>
