/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import auth from './modules/auth';
import subscription from './modules/subscription';

Vue.use(Vuex);

// localStorage 사용
const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: state => ({  // 저장하고 싶은 state만 저장
    test_storage: state.test_storage,
    auth: state.auth
  })
});

export default new Vuex.Store({
  state: {
    test_storage: 0,
    test: 0
  },
  actions: {
    ROOT_TEST(context) {
      context.commit('SET_TEST');
      return null;
    },
  },
  mutations: {
    SET_TEST(state) {
      state.test_storage = state.test_storage + 1;
      state.test = state.test + 1;
    },
  },
  getters: {},
  modules: {
    auth,
    subscription
  },
  plugins: [vuexLocalStorage.plugin],
});
