/**
 * https://kdinner.tistory.com/53
 */
import Vue from 'vue';

// bus.js
// import {bus} from './bus.js'
//export const bus = new Vue();

// App.vue
// import Bus from './bus.js'
export default new Vue();
