<template>
  <div>
    <div>
      <!-- 로딩 markup -->
      <div
        v-if="item.type === 'loading'"
        class="loading-wrap"
        style="display:block"
      >
        <div class="loading">
          <figure class="loading-img"></figure>
          <p class="loading-text"></p>
        </div>
      </div>

      <!---
    * 알림/팝업 common-ui.js 
    * .active 알림창 열림
    * .alert_close_btn 알림창 닫힘 버튼
  -->
      <div class="alert-mask active"></div>

      <!-- alertPopup -->
      <div class="popup-area alert active" v-if="item.type === 'alertPopup'">
        <!--클래스 active 추가시 등장-->
        <div class="pop-header">
          <button class="pop-x alert_close_btn" @click="onClose(false)">
            CLose</button
          ><!-- .alert_close_btn-->
        </div>
        <div class="pop-contents">
          <div class="alert-text">
            <p v-html="item.message"></p>
            <!-- <p>아이디가 존재하지 않거나 비밀번호가 일치하지 않습니다.</p> -->
          </div>
          <div class="btns-bottom">
            <button type="button" class="alert_close_btn" @click="onOk()">
              OK
            </button>
          </div>
        </div>
      </div>

      <!--confirm-->
      <div class="popup-area alert active" v-if="item.type === 'confirmPopup'">
        <div class="pop-header">
          <button class="pop-x alert_close_btn" @click="onClose(false)">
            Close
          </button>
        </div>
        <div class="pop-contents">
          <div class="alert-text">
            <p v-html="item.message"></p>
          </div>
          <div class="btns-bottom">
            <button type="button" class="bt-half" @click="onOk()">
              OK</button
            ><!--.bt-half 버튼width:50%-->
            <button
              type="button"
              class="bt-half alert_close_btn"
              @click="onClose(false)"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <!--팝업 : 서비스 준비중 -->
      <!--2020-09-28 경진대회-->
      <div
        class="popup-area xsmall active"
        v-if="item.type === 'popup_ready_service'"
      >
        <div class="pop-contents">
          <figure class="exh-pop-f1">
            <img src="@/assets/libs/images/exhibition/ico_notice.png" />
            <figcaption>Service Unavaliable.</figcaption>
          </figure>
          <p class="exh-pop-p1">
            We are preparing the service as soon as possible.<br />
            <br />
            Please come back later.
          </p>
          <button @click="onClose(false)" class="exh-pop-bt-red pop_close_btn">
            Close
          </button>
          <button @click="onClose(false)" class="pop-x pop_close_btn">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    // 닫기 / 취소
    onClose(response) {
      this.$emit('close', this.item, response);
    },
    // 확인
    onOk() {
      this.onClose(true);
    },
    keyup(e) {
      if ('Enter' === e.key) {
        this.onOk();
      }
    },
  },
  created() {
    document.addEventListener('keyup', this.keyup);
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyup);
  },
};
</script>

<style></style>
