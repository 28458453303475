export default {
  namespaced: true,
  state: {
    alarmRuleItems: [],
  },
  actions: {},
  mutations: {
    SET_ALARM_RULE_ITEMS(state, data) {
      state.alarmRuleItems = data;
    },
  },
  getters: {},
};
