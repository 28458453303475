<template>
  <header class="header">
    <div class="head-inner">
      <h1 class="h1-logo">
        <router-link to="/"
          ><img src="@/assets/libs/images/logo_gnb.png" alt="redwood logo"
        /></router-link>
      </h1>
      <nav class="nav">
        <ul class="main-menu">
          <li id="liMnuService">
            <!-- <router-link to="/intro">About</router-link> -->
            <router-link to="/intro">{{ $t('menu.about') }}</router-link>
            <ul class="sub-menu">
              <li>
                <router-link to="/intro">
                  {{ $t('subMenu.introdution') }}
                </router-link>
              </li>
              <li>
                <router-link to="/notice">
                  {{ $t('subMenu.notice') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/guide">{{ $t('menu.support') }}</router-link>

            <ul class="sub-menu">
              <li>
                <router-link to="/guide">{{ $t('subMenu.guide') }}</router-link>
              </li>
              <li>
                <router-link to="/qna">{{ $t('subMenu.qna') }}</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/platform">{{ $t('menu.solutions') }}</router-link>

            <ul class="sub-menu">
              <li>
                <router-link to="/platform">
                  {{ $t('subMenu.platform') }}
                </router-link>
              </li>
              <li>
                <router-link to="/environment">
                  {{ $t('subMenu.environment') }}
                </router-link>
              </li>
              <li>
                <router-link to="/status">
                  {{ $t('subMenu.status') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isLogin">
            <router-link to="/manager">
              {{ $t('menu.serviceAdmin') }}
            </router-link>

            <ul class="sub-menu">
              <li>
                <router-link to="/manager">
                  {{ $t('subMenu.manager') }}
                </router-link>
              </li>
              <li>
                <router-link to="/user">
                  {{ $t('subMenu.user') }}
                </router-link>
              </li>
              <li>
                <router-link to="/transaction">
                  {{ $t('subMenu.transaction') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isLogin">
            <router-link to="/subscription">
              {{ $t('menu.subscriptionAdmin') }}
            </router-link>

            <ul class="sub-menu">
              <li>
                <router-link to="/subscription">
                  {{ $t('subMenu.subscription') }}
                </router-link>
              </li>
              <li>
                <router-link to="/alarm">
                  {{ $t('subMenu.alarm') }}
                </router-link>
              </li>
              <li>
                <router-link to="/rule">
                  {{ $t('subMenu.rule') }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div v-if="!isLogin" class="hd-userinfo" id="headerServiceLoginDiv">
        <router-link to="/login" class="top-logout">
          {{ $t('common.login') }}
        </router-link>
      </div>
      <div v-else class="hd-userinfo" id="headerServiceLogoutDiv">
        <router-link to="/manager" class="top-user">
          <b class="name">{{ getLoginUserNm }}</b
          ><span class="duties"></span>
        </router-link>

        <a @click="logout" class="top-logout login-userinfo">
          {{ $t('common.logout') }}
        </a>
      </div>
      <div style="position: relative; float: right;" class="hd-cert">
        <select class="isf term-sel" v-model="lang" @change="changeLang">
          <option value="ko">한국어</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>
    <div class="nav-bar">
      <div class="barbg"></div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      lang: 'en',
    };
  },
  computed: {
    // 로그인 여부
    isLogin() {
      return this.$store.getters['auth/isLogin'];
    },

    getLoginUserNm() {
      return this.$store.getters['auth/userName'];
    },
  },
  methods: {
    async logout() {
      await this.$store
        .dispatch('auth/LOGOUT')
        .then(({ valid, message }) => {
          if (valid) {
            this.$dialogs
              .alert('Successfully logged out.')
              .then(() => this.$router.push('/login'));
          } else {
            this.$dialogs.alert(message);
          }
        })
        .catch(() => {
          this.$dialogs
            .alert(
              'Session has been expired or Logged in from a different browser. Redirecting to redwood health main page.',
            )
            .then(() => this.$router.push('/login'));
        });
    },
    changeLang() {
      if (this.lang === 'ko') {
        window.location.href = `${process.env.VUE_APP_URL_PORTAL}`;
      }
    },
    goMarket() {
      this.$dialogs.ready_service();
    },
  },
};
</script>

<style></style>
