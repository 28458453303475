import axios from 'axios';
import { setAuthInterceptors, setInterceptors } from './common/interceptors'; // fhapi server

// portal API
function createPortalInstance(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_PORTAL}${url}`,
  });
  return setInterceptors(instance);
}

function createAuthPoralInstance(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_PORTAL}${url}`,
  });
  return setAuthInterceptors(instance);
}

// redwood portal
export const common = createPortalInstance('/common');
export const main = createPortalInstance('/main');
export const notice = createPortalInstance('/support/notice');
export const qna = createPortalInstance('/support/qna');
export const request = createPortalInstance('/request');
export const serviceInterceptor = createAuthPoralInstance('/service');
export const service = createPortalInstance('/service');
export const subscriptionInterceptor = createAuthPoralInstance('/subscription');
