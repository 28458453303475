<template>
  <div class="wrapper">
    <Header />
    <transition>
      <router-view></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
export default {
  components: { Header, Footer },
};
</script>

<style>
@import './assets/libs/css/common.css';
@import './assets/libs/css/webfont.css';
</style>
