import Vue from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import Dialogs from '@/components/common/Dialogs';
import SubHeader from '@/components/common/SubHeader';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { ValidationObserver, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import i18n from '@/utils/i18n';

Vue.config.productionTip = false;

Dialogs.install = (Vue, options) => {
  Vue.prototype.$dialogs = new (Vue.extend(Dialogs))({ propsData: options });
};

Vue.use(Dialogs);

Vue.component('SubHeader', SubHeader);
Vue.component('DatePicker', DatePicker);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('en', en);
Vue.component('ValidationObserver', ValidationObserver);

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app');
