const routes = [
  {
    path: '/intro',
    component: () => import('@/views/intro/IntroView.vue'),
  },
  {
    path: '/notice',
    component: () => import('@/views/intro/notice/NoticeView.vue'),
    name: 'notice',
    props: true,
  },
  {
    path: '/notice/:noticeNo',
    component: () => import('@/views/intro/notice/NoticeDetailView.vue'),
    name: 'noticeDetail',
    props: true,
  },
];

export default routes;
