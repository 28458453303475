import { getCommonCodes } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';
import store from '@/store/index';

const routes = [
  {
    path: '/subscription',
    name: 'subscription',
    props: true,
    component: () =>
      import('@/views/subscription/subscription/SubscriptionView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/subscriptionInsert',
    name: 'subscriptionInsert',
    component: () =>
      import('@/views/subscription/subscription/SubscriptionInsertView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/alarm',
    name: 'alarm',
    component: () => import('@/views/subscription/alarm/AlarmView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('@/views/subscription/rule/RuleView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      getCommonCodes(`${COMMON_GROUP_CODE.CODE_RULE_HEALTH_CODE}`)
        .then(response => {
          store.commit('subscription/SET_ALARM_RULE_ITEMS', response.data);
          next();
        })
        .catch(error => {
          console.log('error', error);
        });
    },
  },
  {
    path: '/rule/insert',
    name: 'ruleInsert',
    component: () => import('@/views/subscription/rule/RuleInsertView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name === 'rule') {
        next();
      } else {
        next('/rule');
      }
    },
  },
  {
    path: '/rule/:alarmRuleSn',
    name: 'ruleDetail',
    component: () => import('@/views/subscription/rule/RuleDetailView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name === 'rule') {
        next();
      } else {
        next('/rule');
      }
    },
  },
];

export default routes;
