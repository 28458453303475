import store from '@/store/index';
import { handleError } from './common.js';
import router from '@/routes/index.js';

function setAuthInterceptors(instance) {
  // Add a request interceptor
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      // console.log(config);
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${store.state.auth.portalToken}`,
      };
      return config;
    },
    function(error) {
      // Do something with request error

      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      handleError(error);
      if (
        error.response.config.url != '/session/logout' &&
        error.response.status == 401
      ) {
        alert(
          'Session has been expired or Logged in from a different browser. Redirecting to redwood health main page.',
        );
        router.push('/login');
      }
      return Promise.reject(error);
    },
  );
  return instance;
}
function setInterceptors(instance) {
  // Add a request interceptor
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      // console.log(config);
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
      };
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      handleError(error);
      return Promise.reject(error);
    },
  );
  return instance;
}

export { setAuthInterceptors, setInterceptors };
