/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import mainRoutes from './mainRouter';
import introRoutes from './introRouter';
import requestRoutes from './requestRouter';
import serviceRoutes from './serviceRouter';
import supportRoutes from './supportRouter';
import subscriptionRouter from './subscriptionRouter';

const otherRoutes = [
  {
    path: '/error',
    component: () => import('@/views/common/ErrorPage.vue'),
    meta: { anonymous: true },
  },
  {
    path: '*',
    component: () => import('@/views/common/NotFoundPage.vue'),
    meta: { anonymous: true },
  },
];

let allRoutes = [];
allRoutes = allRoutes.concat(
  mainRoutes,
  introRoutes,
  requestRoutes,
  serviceRoutes,
  supportRoutes,
  subscriptionRouter,
  otherRoutes,
);

Vue.use(VueRouter);

const routes = allRoutes;

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await store.dispatch('auth/SESSION_CHECK').catch(() => {
      store.dispatch('auth/CLEAR_SESSION');
      alert(
        'Session has been expired or Logged in from a different browser. Redirecting to redwood health main page.',
      );
      next('/login');
    });
    next();
  } else {
    next();
  }
});

export default router;
