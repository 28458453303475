import { common } from '@/api/index';

/**
 * 공통코드 목록 조회
 * @param {*} groupCodeId
 * @returns
 */
function getCommonCodes(groupCodeId) {
  return common.get(`/code/${groupCodeId}`);
}

export { getCommonCodes };
